// Utilities
import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
  state: () => {
    return { user: { role: "", name: "" } }
  },
  actions: {
    setUser(user) {
      this.user = user;
    },
  },
})
