export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/app/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/callback',
    name: '/callback',
    component: () => import('/app/src/pages/callback.vue'),
    /* no children */
  },
  {
    path: '/dashboard',
    name: '/dashboard',
    component: () => import('/app/src/pages/dashboard.vue'),
    children: [
      {
        path: '',
        name: '/dashboard/',
        component: () => import('/app/src/pages/dashboard/index.vue'),
        /* no children */
      },
      {
        path: 'account',
        /* internal name: '/dashboard/account' */
        /* no component */
        children: [
          {
            path: '',
            name: '/dashboard/account/',
            component: () => import('/app/src/pages/dashboard/account/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'admin',
        /* internal name: '/dashboard/admin' */
        /* no component */
        children: [
          {
            path: '',
            name: '/dashboard/admin/',
            component: () => import('/app/src/pages/dashboard/admin/index.vue'),
            /* no children */
          },
          {
            path: 'adminmap',
            name: '/dashboard/admin/adminmap',
            component: () => import('/app/src/pages/dashboard/admin/adminmap.vue'),
            /* no children */
          },
          {
            path: 'blacklist',
            name: '/dashboard/admin/blacklist',
            component: () => import('/app/src/pages/dashboard/admin/blacklist.vue'),
            /* no children */
          },
          {
            path: 'blacklists',
            name: '/dashboard/admin/blacklists',
            component: () => import('/app/src/pages/dashboard/admin/blacklists.vue'),
            /* no children */
          },
          {
            path: 'export',
            name: '/dashboard/admin/export',
            component: () => import('/app/src/pages/dashboard/admin/export.vue'),
            /* no children */
          },
          {
            path: 'map',
            name: '/dashboard/admin/map',
            component: () => import('/app/src/pages/dashboard/admin/map.vue'),
            /* no children */
          },
          {
            path: 'Search',
            name: '/dashboard/admin/Search',
            component: () => import('/app/src/pages/dashboard/admin/Search.vue'),
            /* no children */
          },
          {
            path: 'Settings',
            name: '/dashboard/admin/Settings',
            component: () => import('/app/src/pages/dashboard/admin/Settings.vue'),
            /* no children */
          },
          {
            path: 'Statistics',
            name: '/dashboard/admin/Statistics',
            component: () => import('/app/src/pages/dashboard/admin/Statistics.vue'),
            /* no children */
          },
          {
            path: 'users',
            name: '/dashboard/admin/users',
            component: () => import('/app/src/pages/dashboard/admin/users.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'applications',
        /* internal name: '/dashboard/applications' */
        /* no component */
        children: [
          {
            path: '',
            name: '/dashboard/applications/',
            component: () => import('/app/src/pages/dashboard/applications/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            name: '/dashboard/applications/[id]',
            component: () => import('/app/src/pages/dashboard/applications/[id].vue'),
            /* no children */
          },
          {
            path: 'new',
            /* internal name: '/dashboard/applications/new' */
            /* no component */
            children: [
              {
                path: 'app',
                name: '/dashboard/applications/new/app',
                component: () => import('/app/src/pages/dashboard/applications/new/app.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'areamap',
        name: '/dashboard/areamap',
        component: () => import('/app/src/pages/dashboard/areamap.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/logout',
    name: '/logout',
    component: () => import('/app/src/pages/logout.vue'),
    /* no children */
  }
]
