// Plugins
import { registerPlugins } from '@/plugins'
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import introspectToken from './common/token.js'
// import "bootstrap";
import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap;
// Components
import App from './App.vue'
import i18n from "./i18n" // <--- 2
import * as Sentry from "@sentry/vue";


// Composables
// import { createApp } from 'vue'
import { createApp } from 'vue/dist/vue.esm-bundler'
import { useAppStore } from '@/stores/app'
import 'leaflet/dist/leaflet.css';

const app = createApp(App)

app.use(i18n)



app.config.globalProperties.$formatDate= function (date) {
    const options = { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit', 
        hour: '2-digit', 
        minute: '2-digit' 
      };
      const formattedDate = new Date(date).toLocaleDateString(undefined, options);
      return formattedDate;
}

app.config.globalProperties.$formatDecimal= function(amount, tofixed) {
    return Number(amount).toFixed(tofixed);
}

registerPlugins(app)

const router = app.config.globalProperties.$router;


const accessToken = localStorage.getItem('jwtToken');
if (accessToken) {
    const store = useAppStore()
    introspectToken(import.meta.env.VITE_API_URL + "api/v1/introspect", accessToken)
        .then((user) => {
            store.setUser(user);
        })
        .catch((error) => {
            localStorage.removeItem('jwtToken')
            console.error('Failed to introspect token:', error);
        });
}


Sentry.init({
    app,
    dsn: "https://4d3fcf37fd2605b7e308b3f6562e8e4d@o4507984856088576.ingest.de.sentry.io/4507984858447952",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ["support-akamas.com","localhost", /^https:\/\/api\.support-akamas\.com/],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  
app.mount('#app')
