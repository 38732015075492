import axios from 'axios';

export default function introspectToken(introspectUrl, accessToken) {
  return new Promise((resolve, reject) => {
    axios.post(introspectUrl, null, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          throw new Error('token introspection failed');
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}