/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
import { createRouter, createWebHistory } from 'vue-router/auto'
import { setupLayouts } from 'virtual:generated-layouts'


function authMiddleware(to, from, next) {
  const token = localStorage.getItem('jwtToken');
  if(to.name == "/" || to.name == "/callback" || to.name == "/logout") {
    next();
    return;
  }

  if (token) {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
    const currentTime = Date.now();
    
    if (currentTime > expirationTime) {
      localStorage.removeItem("jwtToken")
      next("/");
      return;
    } else {
      next()
      return;
    }
  } 

  next("/");
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  extendRoutes: setupLayouts,
})

router.beforeEach(authMiddleware);

export default router