import { createI18n } from "vue-i18n";
import en from './en';
import gr from './gr';

const messages = {
    en: en,
    gr: gr
}

export default createI18n({
  locale: localStorage.getItem('appLocale') || 'gr',
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  legacy: false,
  globalInjection: true, 
  messages
})